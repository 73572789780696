 import axios from "axios";
import endpoints from "endpoints";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export interface IPlan {
  id: string;
  id_cliente: string;
  customer_id: string;
  document: string;
  data: string;
  created_at: string;
  updated_at: string;
  status: string;
  hash: string;
  views: string;
  price: string;
  name: string;
  description: string;
  redirect: string;
  redirect_cancelled: string;
  redirect_pending: string;
  sku: string;
  stock: string;
  stockstatus: string;
  type: string;
  interval: string;
  period: string;
  length: string;
  signup_fee: string;
  trial_length: string;
  trial_period: string;
  inventory: string;
  recovery_failed_freq: string;
  c_payment_methods: string;
  payment_method_sel: string;
  tag: string;
  active_redirect: string;
}

type CreatePlanParams = Pick<IPlan, "id" | "hash" | "price" | "name"> & { password: string};
type UpdatePlanParams = Pick<IPlan, "id" | "hash" | "price" | "name">;

const listPlan = async () => axios.get(endpoints.listPlan);
const createPlan = async (values: CreatePlanParams) => axios.post(endpoints.createPlan, values);
const updatePlan = async (values: UpdatePlanParams) => axios.put(`${endpoints.updatePlan}/${values.id}`, values);
const deletePlan = async (id: string) => axios.delete(`${endpoints.deletePlan}/${id}`);

export const usePlanList = () => useQuery(endpoints.listPlan, async () => {
  const data = await listPlan() 
  return data as unknown as IPlan[];
});

export const useCreatePlan = () =>
  useMutation({
    mutationFn: createPlan,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listPlan, (oldData: IPlan[] = []) => [newItem, ...oldData]);
      window.location.reload();
      toast.success(`Plano criado com sucesso!`);
    },
  });

export const useUpdatePlan = () =>
  useMutation({
    mutationFn: updatePlan,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listPlan, (oldData: IPlan[] = []) =>
      //   oldData.map((item) => (item.id === newItem.id ? newItem : item))
      // );
      window.location.reload();
      toast.success(`Plano atualizado com sucesso!`);
    },
  });

  
export const useDeletePlan = () =>
  useMutation({
    mutationFn: deletePlan,
    onSuccess: ({ data: newItem }) => {
      window.location.reload();
      toast.success(`Plano excluído com sucesso!`);
    },
  });


