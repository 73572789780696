import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 200, height: 50, ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width={"100%"} height={"100%"} viewBox="0 0 37.908 38.045">
        <defs>
          <style>{'.b{fill:#fff}'}</style>
        </defs>
        <path
          d="M68.358 349.024a5.391 5.391 0 0 1 3.5-3.231 6 6 0 0 1 1.375-.183c2.517-.007 5.639 0 8.157 0h.41v-8.11a5.645 5.645 0 0 1 1.781-4.324 4.959 4.959 0 0 1 2.56-1.3c-.242.4-.461.732-.656 1.082a10.916 10.916 0 0 0-1.341 4.291 16.238 16.238 0 0 0 .851 6.381c.494 1.631 1.992 4.749 1.727 5.534-.144.427-.39.815-.563 1.233a6.827 6.827 0 0 0-.571 2.684A4.1 4.1 0 0 0 86 354.98a5.131 5.131 0 0 1 .231 2.387 36.427 36.427 0 0 1-1.55 8.533c-.355.967-.809 1.894-1.245 2.9a5.448 5.448 0 0 1-1.257-1.931 5.987 5.987 0 0 1-.379-2.2q-.005-3.832 0-7.665v-.459h-.426c-2.491 0-5.586.008-8.077 0a5.289 5.289 0 0 1-5.062-3.853 5.189 5.189 0 0 1-.192-2.34c5.085 3.2 10.965 4.088 16.964 3.927-.359-1.1.246-3.24.4-3.706.091-.284-.064-.474-.347-.423a4.777 4.777 0 0 0-.9.273c-4.329 1.958-14.46-.837-15.802-1.399Z"
          transform="translate(-67.987 -331.881)"
          style={{
            fill: '#a2cbf2',
          }}
        />
        <path
          className="b"
          style={{
            fill: PRIMARY_MAIN,
          }}
          d="M37.645 17.403a.636.636 0 0 0-.345-.4c-1.243-.553-6.065-2.659-15.771 3.638a.386.386 0 0 0-.181.269c-.208 1.533-.36 3.076-.612 4.6a35.231 35.231 0 0 1-2.24 8.422c-.578 1.2-1.26 2.337-1.906 3.522.034.019.117.071.207.112a4.89 4.89 0 0 0 2.8.43 5.3 5.3 0 0 0 4.468-5.35v-8.229h.465c2.451 0 5.657.011 8.107 0a5.355 5.355 0 0 0 5.008-7.014Zm-4.628 3.715S31 20.767 31.06 18.973c0 0 .056-1.734 2.452-1.44a1.644 1.644 0 0 1 .54.164 2.242 2.242 0 0 1 1.4 1.915s-.023 1.755-2.435 1.507Z"
        />
        <path
          className="b"
          style={{
            fill: PRIMARY_MAIN,
          }}
          d="M32.906 13.735c-2.561-.041-5.878-.011-8.44-.011h-.413V5.458a5.172 5.172 0 0 0-.026-.585 5.3 5.3 0 0 0-4.76-4.786.47.47 0 0 0-.515.262 9.907 9.907 0 0 0-1.14 7.531 38.5 38.5 0 0 0 1.8 5.308c.678 1.754 1.792 4.8 1.855 5.168a73.11 73.11 0 0 1 7.62-2.478 14.942 14.942 0 0 1 6.53-.3l1.436.312a4.957 4.957 0 0 0-3.947-2.155Zm-11.43-7.358a1.414 1.414 0 0 1-.031.206c-.1.428-.536 1.916-1.94 1.93a1.169 1.169 0 0 1-.3-.035 1.667 1.667 0 0 1-1.2-1.687 3.019 3.019 0 0 1 .625-2.129 1.725 1.725 0 0 1 1.39-.729s1.644.02 1.456 2.444Z"
        />
      </svg> */}
      <img src='/logo/logo.png' alt="logo" width={"100%"} height={"100%"} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
