import { FC } from 'react';
import { Stack, Typography, Grid, Card, Divider, Hidden, Link } from '@mui/material';
import { Customer, useCustomerData } from '../customers.hooks';
import Avatar from 'components/Avatar';
import Record from 'components/Record';
import { fDate, fMobilePhone, fCep } from 'utils/formatTime';

interface CustomerInfoCardProps {
  customer: Customer;
}

// const SectionsRow: FC = ({ children }) => (
//   <Grid item container spacing={2}>
//     {children}
//   </Grid>
// );

// const Section: FC<{ title: string }> = ({ title, children }) => (
//   <Grid item xs={12} md={6}>
//     <Typography variant="subtitle1">{title}</Typography>
//     {children}
//   </Grid>
// );


export default function CustomerInfoCard(props: CustomerInfoCardProps) {
  const { customer } = props;
  const { hash, name, email, phones, address, code, document, type, gender, delinquent, birthdate } =
    customer || {};

  return (
    <Card sx={{ mb: 3 }}>
      <Grid sx={{ px: 4, py: 2 }} container spacing={4}>
        <Grid item xs={12} md={4}>
          <Stack height={'100%'} width={'100%'} direction={'row'} style={{ minWidth: 200 }}>
            <Stack flex={1} spacing={2}>
                <Stack alignItems={'center'} spacing={2} style={{ minWidth: 200 }}>
                    <Avatar sx={{ width: 80, height: 80 }} alt={name} src={`${code}/${email}`} />
                    <Typography variant="subtitle1">{name}</Typography>
                </Stack>
            </Stack>
            <Stack flex={1} spacing={2}>
              <Stack>
                <Record label={'Email'}>
                  <Link href={`mailto:${email}`}>{email}</Link>
                </Record>
                <Record label={'Telefone'} value={(phones[0]?.country_code) ? fMobilePhone(`${phones[0]?.country_code}${phones[0]?.area_code}${phones[0]?.number}`) : "não cadastrado."} />
                <Record label={'Nasc'} value={fDate(birthdate)} />
              </Stack>
              <Stack>
                {/* <Section title="Entrega / Cobrança"> */}
                  <Record label={'Endereço'} value={(address[0]?.line_1) ? `${address[0]?.line_1}, ${address[0]?.line_2} - ${address[0]?.city}, ${address[0]?.state}` : "não cadastrado."} />
                  <Record label={'CEP'} value={(address[0]?.zip_code) ? fCep(address[0]?.zip_code) : "não cadastrado."} />
                {/* </Section> */}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
