import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import CustomerDetailsPage from 'pages/dashboard/customers/CustomerDetailsPage';
import ViewSubscriptionPage from 'pages/dashboard/subscriptions/ViewSubscriptionPage';
import AffiliateListPage from 'pages/dashboard/affiliates/AffiliateListPage';
import FeeListPage from 'pages/dashboard/fees/FeeListPage';
import InvoiceListPage from 'pages/dashboard/invoices/InvoiceListPage';
import PlanListPage from 'pages/dashboard/plans/PlanListPage';
import ProductListPage from 'pages/dashboard/products/ProductListPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { path: 'login', element: <GuestGuard><LoginPage /></GuestGuard> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" replace />, index: true },
        { path: 'home', element: <HomePage /> },
        // { path: 'insertContent', element: <InsertTemplateContentPage /> },
        {
          path: 'users',
          children: [
            { element: <UsersListPage />, index: true },
          ]
        },
        {
          path: 'affiliates',
          children: [
            { element: <AffiliateListPage />, index: true },
          ]
        },
        {
          path: 'customers',
          children: [
            { element: <CustomersListPage />, index: true },
            { path: 'customers/:id', element: <CustomerDetailsPage /> },
          ]
        },
        {
          path: 'fees',
          children: [
            { element: <FeeListPage />, index: true },
          ]
        },
        {
          path: 'invoices',
          children: [
            { element: <InvoiceListPage />, index: true },
          ]
        },
        {
          path: 'orders',
          children: [
            { element: <OrdersListPage />, index: true },
            { path: 'orders/:id', element: <ViewOrderPage /> },
          ]
        },
        {
          path: 'plans',
          children: [
            { element: <PlanListPage />, index: true },
          ]
        },
        {
          path: 'products',
          children: [
            { element: <ProductListPage />, index: true },
          ]
        },
        {
          path: 'seller',
          children: [
            { element: <SellerPage />, index: true },
          ]
        },
        {
          path: 'subscriptions',
          children: [
            { element: <SubscriptionsListPage />, index: true },
            { path: 'subscriptions/:id', element: <ViewSubscriptionPage />}
          ]
        },
      ],
    },
    // {
    //   path: '/dashboard/orders/create/:id',
    //   element: (
    //     <AuthGuard>
    //       <CreateOrderPage />
    //     </AuthGuard>
    //   ),
    // },
    {
      path: '/dashboard/orders/:id',
      element: <ViewOrderPage />,
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Auth
const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));

// Orders
// const CreateOrderPage = Loadable(lazy(() => import('../pages/dashboard/orders/CreateOrderPage')));
const ViewOrderPage = Loadable(lazy(() => import('../pages/dashboard/orders/ViewOrderPage')));

// Dashboard
const HomePage = Loadable(lazy(() => import('../pages/dashboard/home/HomePage')));
const CustomersListPage = Loadable(lazy(() => import('../pages/dashboard/customers/CustomersListPage')));
const OrdersListPage = Loadable(lazy(() => import('../pages/dashboard/orders/OrdersListPage')));
const SellerPage = Loadable(lazy(() => import('../pages/dashboard/seller/SellerPage')));
const SubscriptionsListPage = Loadable(lazy(() => import('../pages/dashboard/subscriptions/SubscriptionsListPage')));
const UsersListPage = Loadable(lazy(() => import('../pages/dashboard/users/UsersListPage')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
