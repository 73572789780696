import axios from 'axios';
import endpoints from 'endpoints';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Items, Charges, LastTransactions, SubscriptionList, ISubscriptionTemplate, ITemplates } from './subscriptions.types';
import { Customer } from '../customers/customers.hooks';
import { description } from '_mock/text';

type CreateSubscriptionParams = {
  /* 
  templateId: ISubscriptionTemplate["id"];
  customerId: Customer["id"];
  body: string; // <html></html>
  */
  templateId: number;
  data: string;
};

export enum SubscriptionStatus {
  Pago = 'paid',
  Pendente = 'pending',
  Rejeitado = 'rejected',
  Cancelado = 'cancelled',
}

const getCustomerById = async (id: Customer['id']) => axios.get(`${endpoints.getCustomerById}/${id}`);
const getSubscriptionsList = async () => axios.get(endpoints.getSubscriptionsList);
const getSubscriptionTemplates = async () => axios.get(endpoints.getSubscriptionsList);
const getSubscriptionByIds = async (id: ISubscriptionTemplate['id']) =>
  axios.get(endpoints.getSubscriptionById, { params: { id } });
const getSubscriptionById = async (uid: string) => axios.get(`${endpoints.getSubscriptionById}/${uid}`);
const createSubscription = async (values: CreateSubscriptionParams) =>
  axios.post('http://localhost:5001/subscriptions/generate-subscription', values).then(({ data }) => data);

const deleteTemplate = async (values) =>
  axios.post('http://localhost:5001/subscriptions/delete-template', values).then(({ data }) => data);

const listTemplates = async () => axios.get('http://localhost:5001/subscriptions/get-templates');

export const useSubscriptionList = () =>
  useQuery([endpoints.getSubscriptionsList], async () => {
    const data = await getSubscriptionsList();
    
    if(data) 
    {
      return data as unknown as SubscriptionList[];
    }
    
    return [];
    
  });

export const useCustomerSubscriptionList = (uid) =>
  useQuery([`${endpoints.getCustomerById}/${uid}/subscriptions`], async () => {
    const data = await getCustomerById(`${uid}/subscriptions`);
    
    if(data) 
    {
      return data as unknown as SubscriptionList[];
    }
    
    return [];
    
  });

export const useTemplateList = () =>
  useQuery(endpoints.getSubscriptionsList, async () => {
    const { data = [] } = await listTemplates();
    return data;
  });

export const useCustomerData = (customerId: Customer['id']) =>
  useQuery([endpoints.getCustomerById, customerId], async () => {
    const { data } = await getCustomerById(customerId);
    return data?.data?.[0]?.keyValues as Customer;
  });

export const useSubscriptionTemplates = () =>
  useQuery([endpoints.getSubscriptionsList], async () => {
    const { data } = await getSubscriptionTemplates();
    // return data as ITemplates;
    return data;
  });

const replaceSectionWithData = async (templateData, body) => {
  templateData.forEach((element, ei) => {
    Object.keys(element.templateContent).forEach((k, v) => {
      if (k !== 'contentId') {
        body = body.replaceAll(`{{${k}}}`, templateData[ei].templateContent[k]);
      }
    });
  });
  // console.log(body)
  return body;
};

export const useSubscription = (id?: SubscriptionList['id']) =>
  useQuery(
    [endpoints.getSubscriptionById, id],
    async () => {
      const { data } = await getSubscriptionById(id);
      let nb: string;

      data.forEach((val, i) => {
        // if (i !== 0) {
        nb += val.sectionContent.content;
        // }
      });
      const body = await replaceSectionWithData(data, nb);
      return { body, data };
    },
    { enabled: !!id }
  );

export const useSubscriptionData = (uid: string) =>
  useQuery([endpoints.getSubscriptionById, uid], async () => {
    const data = await getSubscriptionById(uid);
    return data as unknown as SubscriptionList;
  });

export const useCreateSubscription = () =>
  useMutation({
    mutationFn: createSubscription,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listSubscriptions, (oldData: ISubscription[] = []) => [newItem, ...oldData]);
      // toast.success(`Created successfully`);
    },
  });

export const useDeleteTemplate = () =>
  useMutation({
    mutationFn: deleteTemplate,
    onSuccess: (_, data) => {
      toast.success(`${data.name} deleted successfully`);
    },
  });

