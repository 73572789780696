import { RHFTextField } from 'components/hook-form';
import React, { useEffect, useState } from 'react';

interface InputMoneyProps  {
    value?: string;
    name: string;
    label: string;
    placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DECIMAL_SIZE = 2;

const InputMoney = ({ name, label, placeholder, value, onChange, ...props }: InputMoneyProps) => {
    const [currentValue, setCurrentValue] = useState<string>(value);

    useEffect(() => {
        const valueString = `${value}`;

        if (!/\D/.test(valueString.replace(',', ''))) {
                
            const sizeSlice = valueString.replace(',','').replace('.','').length - DECIMAL_SIZE;
            
            const newValue = [
                valueString.slice(0, sizeSlice),
                valueString.slice(sizeSlice),
            ].join('.')
            .replace(',', '');

            const valueInt = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'code',
                minimumFractionDigits: DECIMAL_SIZE,
            }).format(Number(newValue))
            .replace('BRL', '')
            .replace('.', '')
            .trim();

            setCurrentValue(valueInt);
        }
    }, []);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueRemoved = event.target.value.replace(',', '');

        const sizeSlice = valueRemoved.length - DECIMAL_SIZE;
        
        const newValue = [
            valueRemoved.slice(0, sizeSlice),
            valueRemoved.slice(sizeSlice),
        ].join(',')
        .replace('.', '');

        setCurrentValue(newValue);

        onChange({
            ...event,
            target: {
                ...event.target,
                value: newValue,
                name,
            },
        });
    };

    return (
        <RHFTextField name={name} label={label} value={currentValue} onChange={handleOnChange} placeholder={placeholder} {...props} />
    );
};

export default InputMoney;
    