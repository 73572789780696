import { Container, Stack, Typography, Grid, Card, Divider, Hidden, Button, TableContainer, Table, TablePagination, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import { useSubscriptionData } from './subscriptions.hooks';
import {  useParams } from 'react-router';
import LoadingScreen from 'components/LoadingScreen';
import CustomerInfoCard from '../customers/components/CustomerInfoCard';
import { fCep, fMobilePhone, fPaymentMethod, fPaymentStatus } from 'utils/formatTime';


export default function ViewSubscriptionPage() {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { data, isLoading } = useSubscriptionData(id);
 
  if (isLoading) {
    return <LoadingScreen isDashboard={false} />;
  }
  if (!data?.status) {
    return <div>Nenhum dado foi encontrado para este pedido.</div>;
  }

  function printArea() {
    const printContents = document.getElementById("printableArea").innerHTML;
    const originalContents = document.body.innerHTML;    
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <Page title={`Venda`}>
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <Card style={{ padding: 24, flexGrow: 1, overflow: 'auto' }}>
        <Grid container spacing={2} style={{ paddingBottom: 30, textAlign: 'right' }}>
          <Button variant="contained" color="primary" onClick={() => printArea()}>Imprimir</Button>
        </Grid>
        <Container maxWidth={themeStretch ? false : 'xl'} id={'printableArea'}>
          <Stack
            sx={{ mb: 2 }}
            flexWrap={'wrap'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            spacing={2}
            style={{ paddingBottom: 30 }}
          >
            <Typography variant="h4" component="h4">
                Venda #{data?.id}            
              </Typography>
              {/* Data do Pedido */}
              <Typography variant="h4" component="h4">
                {new Date(data?.created_at).toLocaleDateString('pt-BR')}
              </Typography>
            </Stack>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <Typography variant="h5" component="h5">
                      {data?.customer?.name}
                    </Typography>
                    <Typography variant="body1" component="p">
                      <strong>CPF:</strong> {data?.customer?.document}<br/>
                      <strong>Telefone:</strong> {(data?.customer?.phones[0]?.country_code) ? fMobilePhone(`${data?.customer?.phones[0]?.country_code}${data?.customer?.phones[0]?.area_code}${data?.customer?.phones[0]?.number}`) : "não cadastrado."}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                  {data?.customer?.address[0].line_1}<br/>
                  {data?.customer?.address[0].line_2} - {data?.customer?.address[0].city}, {data?.customer?.address[0].state}<br/>
                  {fCep(data?.customer?.address[0].zip_code)}<br/>
                      {data?.customer?.email}
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Produto</TableCell>
                          <TableCell align="right">Quantidade</TableCell>
                          <TableCell align="right">Valor</TableCell>
                          <TableCell align="right">Valor Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.items.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { bsubscription: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.description}
                            </TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">{(Number(row.amount) * 0.01).toFixed(2)}</TableCell>
                            <TableCell align="right">{(Number(row.amount) * 0.01).toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">                    
                    <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            <strong>Total</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { bsubscription: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Valor Total:</strong>
                          </TableCell>
                          <TableCell align="right"><strong>{(Number(data?.amount) * 0.01).toFixed(2)}</strong></TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { bsubscription: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Valor Pago:</strong>
                          </TableCell>
                          <TableCell align="right"><strong>{(Number(data?.charges[0]?.lastTransactions?.amount) * 0.01).toFixed(2)}</strong></TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { bsubscription: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Valor Pendente:</strong>
                          </TableCell>
                          <TableCell align="right"><strong>{((Number(data?.amount) - Number(data?.charges[0]?.lastTransactions?.amount)) * 0.01).toFixed(2)}</strong></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell align="right">Valor</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="right">Forma de Pagamento</TableCell>
                          <TableCell align="right">Data do Vencimento</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.charges.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { bsubscription: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {(row?.lastTransactions?.installments) ? row?.lastTransactions?.installments : 1}
                            </TableCell>
                            <TableCell align="right">{(Number(row.amount) * 0.01).toFixed(2)}</TableCell>
                            <TableCell align="right">{fPaymentStatus(row.status)}</TableCell>
                            <TableCell align="right">{fPaymentMethod(row.payment_method)}</TableCell>
                            <TableCell align="right">{row?.lastTransactions?.created_at ? new Date(row?.lastTransactions?.created_at).toLocaleDateString('pt-BR') : ''}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Container>
          </Card>
      </Container>
    </Page>
  );
}
