 import axios from "axios";
import endpoints from "endpoints";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export interface IFee {
  id: string;
  payment_method: string;
  fee: string;
  tipo: string;
  installments: string;
  fee_fixed: string;
  deadline: string;
  gateway: string;
  min: string;
}


const listFees = async () => axios.get(endpoints.listFees);

export const useFeeList = () => useQuery(endpoints.listFees, async () => {
  const data = await listFees() 
  return data as unknown as IFee[];
});

