import { useState } from 'react';
import { Box, Button, Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { useFeeList, IFee } from './fees.hooks';
import { useNavigate } from 'react-router';
import Link from 'theme/overrides/Link';
import { Label, Padding, Share, SpaceBar } from '@mui/icons-material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import { fAVista, fPaymentMethod, fRepasseDeadline } from 'utils/formatTime';

export default function FeeListPage() {
  const { themeStretch } = useSettings();
  const { data = [], isLoading } = useFeeList();
  const [pageSize, setPageSize] = useState(25);
  const [editItem, setEditItem] = useState<IFee | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  
  const onCloseForm = () => {
    setEditItem(null);
    setIsFormModalOpen(false);
  };
  const handleAddClick = () => {
    setEditItem(null);
    setIsFormModalOpen(true);
  };
  const handleEditClick = (row) => {
    setEditItem(row);
    setIsFormModalOpen(true);
  };

  const shareWhatsApp = (row) => {
    // Compartilhar no WhatsApp
    const text = `Olá, tudo bem? Aqui está o link para pagamento pelo ${row.name}: https://pgto.pro/${row.hash}`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
  };

  const shareFacebook = (row) => {
    // Compartilhar no Facebook
    const text = `Olá, tudo bem? Aqui está o link para pagamento pelo ${row.name}: https://pgto.pro/${row.hash}`;
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
  };

  const handleViewClick = (row) => {
    window.open(`https://pgto.pro/${row.hash}`, '_blank');
  };


  const columns = [
    { field: 'payment_method', headerName: 'Método de Pagamento', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {fPaymentMethod(params.row.payment_method)}
        </Stack>
      ),
    },
    { field: 'fee', headerName: 'Taxa %', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {params.row.fee}%
        </Stack>
      ),
    },
    { field: 'installments', headerName: 'Parcelas', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {fAVista(params.row.installments)}
        </Stack>
      ),
    },
    { field: 'deadline', headerName: 'Repasse em', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {fRepasseDeadline(params.row.deadline)}
        </Stack>
      ),
    },
  ];

  return (
    <Page title="Taxas / Prazos">
      <Container
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        maxWidth={themeStretch ? false : 'xl'}
      >
          <Typography variant="h3" component="h1" paragraph>
          Taxas / Prazos
          </Typography>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <HeaderBreadcrumbs
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Taxas / Prazos' },
          ]}
          action={''}
          heading={undefined} sx={undefined} />
        </Stack>
        <Box sx={{ flex: 1 }}>
          <DataGrid
            getRowId={(row) => row.id}
            loading={isLoading}
            disableSelectionOnClick
            rows={data}
            editMode="row"
            columns={columns}
            density={'compact'}
            pagination
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Box>
      </Container>
      
      <Divider />

      <Container style={{ padding: 30 }}>
        <Typography variant="h4" component="h4" paragraph>
          Prazos de Repasse
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Os prazos de recebimento são definidos de acordo com a avaliação do cadastro do cliente e do tipo de produto vendido.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Os critérios de avaliação são definidos pela PagPro através da sua política de risco.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Todas as vendas no cartão de crédito passam por uma análise de risco no anti-fraude híbrido, e a mesma pode ser aprovada para um dos dois prazos de repasse, sendo eles D+2 (3 dia(s) útil(eis)) ou D+14 (15 dia(s) útil(eis)).
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Nossa maior missão é garantir com segurança a maior taxa de aprovação possível para suas vendas.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          <strong>Descrição detalhada dos prazos:</strong>
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          D+2: Quer dizer dia da compra + 2, onde o vendedor receberá o valor da venda em até 3 dia(s) útil(eis).
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          D+14: Quer dizer dia da compra + 14, onde o vendedor receberá o valor da venda em até 15 dia(s) útil(eis).
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Sendo que o prazo de recebimento é contado a partir da data de aprovação da venda. A aprovação da venda pode ocorrer em até 2 dias úteis após a data da compra, de acordo com o tipo de pagamento escolhido pelo cliente.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          <strong>Já as vendas no PIX</strong> são liberadas em até 1 dia útil após a data da compra. E conta com um custo mínimo de R$ 0,99 por transação.
        </Typography>
      </Container>

      <p>&nbsp;</p>
      
    </Page>
  );
}
