 import axios from "axios";
import endpoints from "endpoints";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export interface Address {
  id: string;
  id_cliente: string;
  hash: string;
  line_1: string;
  line_2: string;
  zip_code: string;
  city: string;
  state: string;
  country: string;
  status: string;
  created_at: string;
  updated_at: string;
  customer_id: string;
}

export interface Phones {
  id: string;
  id_cliente: string;
  customer_id: string;
  type: string;
  country_code: string;
  number: string;
  area_code: string;
  created_at: string;
  updated_at: string;
}

export interface Customer {
  id: string;
  id_cliente: string;
  hash: string;
  name: string;
  email: string;
  code: string;
  document: string;
  document_type: string;
  type: string;
  gender: string;
  delinquent: false,
  created_at: Date;
  updated_at: Date;
  birthdate: Date;
  address: Address;
  phones: Phones;
}

export interface ICustomer {
  id: string;
  id_cliente: string;
  hash: string;
  name: string;
  email: string;
  code: string;
  document: string;
  document_type: string;
  type: string;
  gender: string;
  delinquent: false,
  created_at: Date;
  updated_at: Date;
  birthdate: Date;
  imagesRecords: [
    {
      id: string;
      uploadMonth: string;
      image1: string;
      image2: string;
      image3: string;
      image4: string;
      image5: string;
    },
  ];
  address: Address;
  phones: Phones;
  dataOrdersTotal: ItotalOrders;
}

export interface ItotalOrders {
  totalPaid: string;
  totalPending: string;
  totalCanceled: string;
  totalRejected: string;
}

export interface ICustomerListResponse {
  status: number;
  message: string;
  data: ICustomerList | { count: number };
}

export interface ICustomerList {
  limit: number;
  offset: number;
  more: boolean;
  count: number;
  customers: ICustomer[];
}

type UpdateCustomerParams = Pick<ICustomer, "hash" | "name" | "email">;

const getCustomerById = async (hash: ICustomer["hash"]) => axios.get(`${endpoints.getCustomerById}/${hash}`);
const getTotalOrdersCustomerById = async (hash: ICustomer["hash"]) => axios.get(`${endpoints.getCustomerById}/${hash}/ordersTotal`);
const listCustomers = async () => axios.get(endpoints.listCustomers);
const updateCustomer = async (values: UpdateCustomerParams) => axios.put(`${endpoints.updateCustomer}/${values.hash}`, values);

export const sendCustomerResetPasswordEmail = async (email: string) => {
  // await axios.post(endpoints.sendCustomerResetPasswordEmail, {email})
  toast.success(`Reset password email sent successfully`);
};

export const useCustomersList = () =>
  useQuery(endpoints.listCustomers, async () => {
    const data = await listCustomers();

    return data as unknown as ICustomer[];
  });

export const useCustomerData = (hash: Customer["hash"]) => useQuery([endpoints.getCustomerById, hash], async () => {
  const data = await getCustomerById(hash);
  return data as unknown as ICustomer;
});


export const useUpdateCustomer = () =>
  useMutation({
    mutationFn: updateCustomer,
    onSuccess: ({ data: newItem }) => {
      queryClient.setQueryData(endpoints.listCustomers, (oldData: Customer[] = []) =>
        oldData.map((item) => (item.hash === newItem.id ? newItem : item))
      );
      toast.success(`Cliente atualizado com sucesso!`);
    },
  });
