// App orders
const ordersEndpoints = {
  listOrders: '/seller/orders',
  getOrdersList: '/seller/orders',
  createOrder: '/seller/orders',
  getOrderById: '/seller/orders',
};

// App subscriptions
const subscriptionsEndpoints = {
  ListSubscriptions: '/seller/subscriptions',
  getSubscriptionsList: '/seller/subscriptions',
  createSubscription: '/seller/subscriptions',
  getSubscriptionById: '/seller/subscriptions',
};

// App plans
const plansEndpoints = {
  listPlan: '/seller/plan',
  getPlansList: '/seller/plan',
  createPlan: '/seller/plan',
  updatePlan: '/seller/plan',
  deletePlan: '/seller/plan',
  getPlanById: '/seller/plan',
};

// App invoices
const invoicesEndpoints = {
  listInvoice: '/seller/invoice',
  getInvoicesList: '/seller/invoice',
  createInvoice: '/seller/invoice',
  updateInvoice: '/seller/invoice',
  deleteInvoice: '/seller/invoice',
  getInvoiceById: '/seller/invoice',
};

// App products
const productsEndpoints = {
  listProduct: '/seller/product',
  getProductsList: '/seller/product',
  createProduct: '/seller/product',
  updateProduct: '/seller/product',
  deleteProduct: '/seller/product',
  getProductById: '/seller/product',
};

// App customers
const customersEndpoints = {
  listCustomers: '/seller/customers',
  getCustomersList: '/seller/customers',
  createCustomer: '/seller/customers',
  updateCustomer: '/seller/customers',
  deleteCustomer: '/seller/customers',
  getCustomerById: '/seller/customers',
};

// App users
const usersEndpoints = {
  listUsers: '/seller/users',
  getUsersList: '/seller/users',
  createUser: '/seller/users',
  updateUser: '/seller/users',
  deleteUser: '/seller/users',
  getUserById: '/seller/users',
  sendUserResetPasswordEmail: '/seller/users',
};

// App fees
const feesEndpoints = {
  listFees: '/seller/fees',
  getFeesList: '/seller/fees',
  createFee: '/seller/fees',
  updateFee: '/seller/fees',
  deleteFee: '/seller/fees',
  getFeeById: '/seller/fees',
};

// App affiliates
const affiliatesEndpoints = {
  listAffiliate: '/seller/affiliates',
  getAffiliatesList: '/seller/affiliates',
  createAffiliate: '/seller/affiliates',
  updateAffiliate: '/seller/affiliates',
  deleteAffiliate: '/seller/affiliates',
  getAffiliateById: '/seller/affiliates',
};

// App login
const loginEndpoints = {
  login: '/auth/login',
};

const endpoints = {
  ...ordersEndpoints,
  ...subscriptionsEndpoints,
  ...plansEndpoints,
  ...invoicesEndpoints,
  ...productsEndpoints,
  ...customersEndpoints,
  ...usersEndpoints,
  ...feesEndpoints,
  ...affiliatesEndpoints,
  ...loginEndpoints,
};

export default endpoints;
