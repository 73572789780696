 import axios from "axios";
import endpoints from "endpoints";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export interface IAffiliate {
  id: string;
  nome: string;
  email: string;
  celular: string;
  status: string;
  nome_fantasia: string;
  created_at: string;
  referer_id: string;
}

type CreateAffiliateParams = Pick<IAffiliate, "id" | "nome" | "email" | "celular" | "status"> & { password: string};
type UpdateAffiliateParams = Pick<IAffiliate, "id" | "nome" | "email" | "celular" | "status">;

const listAffiliate = async () => axios.get(endpoints.listAffiliate);

export const useAffiliateList = () => useQuery(endpoints.listAffiliate, async () => {
  const data = await listAffiliate() 
  return data as unknown as IAffiliate[];
});


