import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Dialog,
  Button,
  Select, 
  MenuItem,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { IInvoice, useCreateInvoice, useUpdateInvoice } from './invoices.hooks';
import Iconify from 'components/Iconify';
import _ from 'lodash';
import InputMask from 'react-input-mask';

type InvoiceFormDialogProps = {
  open: boolean;
  onClose: () => void;
  editItem?: IInvoice;
};

function InvoiceFormDialog({ open, onClose, editItem }: InvoiceFormDialogProps) {
  const [showPassword, setShowPassword] = useState(false);
  const { mutateAsync: createItemAsync } = useCreateInvoice();
  const { mutateAsync: updateItemAsync } = useUpdateInvoice();
  const isEdit = !!editItem;

  const CreateInvoiceSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório!'),
  });
  const UpdateInvoiceSchema = Yup.object().shape({
    name: Yup.string().required().required('Nome é obrigatório!'),
  });

  const defaultValues = {
    id: '',
    id_cliente: '',
    customer_id: '',
    document: '',
    data: '',
    created_at: '',
    updated_at: '',
    status: '',
    hash: '',
    views: '',
    price: '',
    name: '',
    description: '',
    stockstatus: '',
  };

  const methods = useForm({
    resolver: yupResolver(isEdit ? UpdateInvoiceSchema : CreateInvoiceSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const inputMaskRef = useRef(null);

  useEffect(() => {
    reset(editItem || defaultValues);
    if (inputMaskRef.current) {
      // eslint-disable-next-line no-new
      new InputMask({ mask: '(99) 99999-9999' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, editItem]);

  const onSubmit = async (values) => {
    try {
      if (isEdit) {
        await updateItemAsync(values);
      } else {
        await createItemAsync(values);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? 'Editar produto' : 'Adicionar novo produto'}</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            {isEdit && (
              <Stack spacing={2}>
                <RHFTextField name="name" label="Nome" style={{ width: '100%'}} /> 
                <RHFTextField name="description" label="Descrição" style={{ width: '100%'}} multiline rows={4} />
                <RHFTextField name="price" label="Valor R$" placeholder="0,00" />
                <Stack spacing={2}>
                  <strong>Inventário</strong>
                </Stack>
                <RHFTextField name="sku" label="ID/SKU" placeholder="ID/SKU" />
                <RHFTextField name="stock" label="Estoque" placeholder="0" />
                <Stack spacing={2}>
                  Status do estoque
                <Controller
                  name="stockstatus"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select {...field} variant="outlined" label="Status do estoque" name="stockstatus" fullWidth>
                      <MenuItem value="Disponivel">Disponivel</MenuItem>
                      <MenuItem value="Pausado">Pausado</MenuItem>
                    </Select>
                  )}
                />
                </Stack>
              <Stack spacing={2}>
                <strong>Ative redirecionamentos personalizados<br/></strong>
                Redirecione seus clientes após concluir o pagamento deste produto.
                <br/>
                Insira o URL para redirecionar seus clientes com base no status do pagamento.
                <br/>
                <RHFTextField name="redirect" label="Pagamento aprovado" placeholder="https://www.pagpro.com.br" />
                <RHFTextField name="redirect_pending" label="Pagamento pendente" placeholder="https://www.pagpro.com.br" />
                <RHFTextField name="redirect_cancelled" label="Pagamento cancelado" placeholder="https://www.pagpro.com.br" />
              </Stack>
            </Stack>
            )}
            {!isEdit && (
              <Stack spacing={2}>
                <RHFTextField name="name" label="Nome" style={{ width: '100%'}} /> 
                <RHFTextField name="description" label="Descrição" style={{ width: '100%'}} multiline rows={4} />
                <RHFTextField name="price" label="Valor R$" placeholder="0,00" />
                <Stack spacing={2}>
                  <strong>Inventário</strong>
                </Stack>
                <RHFTextField name="sku" label="ID/SKU" placeholder="ID/SKU" />
                <RHFTextField name="stock" label="Estoque" placeholder="0" />
                <Stack spacing={2}>
                  Status do estoque
                <Controller
                  name="stockstatus"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select {...field} variant="outlined" label="Status do estoque" name="stockstatus" fullWidth>
                      <MenuItem value="Disponivel">Disponivel</MenuItem>
                      <MenuItem value="Pausado">Pausado</MenuItem>
                    </Select>
                  )}
                />
                </Stack>
              <Stack spacing={2}>
                <strong>Ative redirecionamentos personalizados<br/></strong>
                Redirecione seus clientes após concluir o pagamento deste produto.
                <br/>
                Insira o URL para redirecionar seus clientes com base no status do pagamento.
                <br/>
                <RHFTextField name="redirect" label="Pagamento aprovado" placeholder="https://www.pagpro.com.br" />
                <RHFTextField name="redirect_pending" label="Pagamento pendente" placeholder="https://www.pagpro.com.br" />
                <RHFTextField name="redirect_cancelled" label="Pagamento cancelado" placeholder="https://www.pagpro.com.br" />
              </Stack>
            </Stack>
            )}
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Salvar
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default InvoiceFormDialog;
