 import axios from "axios";
import endpoints from "endpoints";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export interface IInvoice {
  id: string;
  id_cliente: string;
  customer_id: string;
  document: string;
  data: string;
  created_at: string;
  updated_at: string;
  status: string;
  hash: string;
  views: string;
  price: string;
  name: string;
  description: string;
  redirect: string;
  redirect_cancelled: string;
  redirect_pending: string;
  sku: string;
  stock: string;
  stockstatus: string;
  type: string;
  interval: string;
  period: string;
  length: string;
  signup_fee: string;
  trial_length: string;
  trial_period: string;
  inventory: string;
  recovery_failed_freq: string;
  c_payment_methods: string;
  payment_method_sel: string;
  tag: string;
}

type CreateInvoiceParams = Pick<IInvoice, "id" | "hash" | "price" | "name"> & { password: string};
type UpdateInvoiceParams = Pick<IInvoice, "id" | "hash" | "price" | "name">;

const listInvoice = async () => axios.get(endpoints.listInvoice);
const createInvoice = async (values: CreateInvoiceParams) => axios.post(endpoints.createInvoice, values);
const updateInvoice = async (values: UpdateInvoiceParams) => axios.put(`${endpoints.updateInvoice}/${values.id}`, values);
const deleteInvoice = async (id: string) => axios.delete(`${endpoints.deleteInvoice}/${id}`);

export const useInvoiceList = () => useQuery(endpoints.listInvoice, async () => {
  const data = await listInvoice() 
  return data as unknown as IInvoice[];
});

export const useCreateInvoice = () =>
  useMutation({
    mutationFn: createInvoice,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listInvoice, (oldData: IInvoice[] = []) => [newItem, ...oldData]);
      window.location.reload();
      toast.success(`Link crado com sucesso!`);
    },
  });

export const useUpdateInvoice = () =>
  useMutation({
    mutationFn: updateInvoice,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listInvoice, (oldData: IInvoice[] = []) =>
      //   oldData.map((item) => (item.id === newItem.id ? newItem : item))
      // );
      window.location.reload();
      toast.success(`Link atualizado com sucesso!`);
    },
  });

  
export const useDeleteInvoice = () =>
  useMutation({
    mutationFn: deleteInvoice,
    onSuccess: ({ data: newItem }) => {
      window.location.reload();
      toast.success(`Link excluído com sucesso!`);
    },
  });


