import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMM yyyy');
}

export function fDateBR(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateBusca(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fPaymentStatusOther(paymentStatus)
{
  // Faça o tratamento inverso de portugues para ingles
  switch (paymentStatus) {
    case 'Pago':
      return 'paid';
    case 'Pendente':
      return 'pending';
    case 'Recusado':
      return 'refused';
    default:
      return '';
  }
}

export function fMobilePhone(phone)
{
  const ddd = phone.substring(0, 2);
  const first = phone.substring(2, 3);
  const second = phone.substring(3, 7);
  const third = phone.substring(7, 11);
  return `(${ddd}) ${first} ${second}-${third}`;
}

export function fMobilePhone2(phone)
{
  const ddd = phone.substring(0, 2);
  const first = phone.substring(2, 3);
  const second = phone.substring(3, 7);
  const third = phone.substring(7, 11);
  return `(${ddd}) ${first} ${second}-${third}`;
}

export function fCep(cep)
{
  const first = cep.substring(0, 5);
  const second = cep.substring(5, 8);
  return `${first}-${second}`;
}

export function fCpf(cpf)
{
  const first = cpf.substring(0, 3);
  const second = cpf.substring(3, 6);
  const third = cpf.substring(6, 9);
  const fourth = cpf.substring(9, 11);
  return `${first}.${second}.${third}-${fourth}`;
}

export function fCnpj(cnpj)
{
  const first = cnpj.substring(0, 2);
  const second = cnpj.substring(2, 5);
  const third = cnpj.substring(5, 8);
  const fourth = cnpj.substring(8, 12);
  const fifth = cnpj.substring(12, 14);
  return `${first}.${second}.${third}/${fourth}-${fifth}`;
}

export function fPaymentMethod(paymentMethod)
{
  switch (paymentMethod) {
    case 'credit_card':
      return 'Cartão de crédito';
    case 'debit_card':
      return 'Cartão de débito';
    case 'money':
      return 'Dinheiro';
    case 'pix':
      return 'PIX';
    default:
      return '';
  }
}

export function fPaymentStatus(paymentStatus)
{
  switch (paymentStatus) {
    case 'paid':
      return 'Pago';
    case 'pending':
      return 'Pendente';
    case 'refused':
      return 'Recusado';
    default:
      return '';
  }
}

export function fTimeStamp(date) {
  const formattedDate = new Date(Number(date));
  return format(new Date(formattedDate), 'dd MMM yyyy')
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}


export function fAVista(valor) {
  /// Retornar a frase a vista se o valor for 1, caso contrario retorna acrescentando o x depois do número
  if (valor === 1) {
    return 'A vista';
  }
  return `${valor}x`;
}

export function fRepasseDeadline(deadline) {
  // Separar os dados da deadline D+1, D+2, D+3 e traduzir em 02 dia(s), 03 dia(s), 04 dia(s)
  const first = deadline.substring(0, 1);
  const second = deadline.substring(2, 3);
  return `${first}${second} dia(s)`;
}