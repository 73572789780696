import { useState } from 'react';
import { Box, Container, Stack, Typography, Grid, Card, Divider, Hidden, Button, Link } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { ItotalOrders, useCustomerData } from './customers.hooks';
import { useNavigate, useParams } from 'react-router';
import LoadingScreen from 'components/LoadingScreen';
import CustomerInfoCard from './components/CustomerInfoCard';

export default function CustomerDetailsPage() {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { data: customer, isLoading } = useCustomerData(id);
  const { hash, name, email } = customer || {};
  const navigate = useNavigate();

  const { totalPaid = 0, totalPending = 0, totalCanceled = 0, totalRejected = 0 } = customer?.dataOrdersTotal || {};

  const handleViewClick = (uid) => {
    navigate(`/dashboard/customers/${uid}/orders`);
  };
  if (isLoading) {
    return <LoadingScreen isDashboard={false} />;
  }
  if (!customer) {
    return <div>Cliente com dados incompletos.</div>;
  }

  return (
    <Page title="Cliente">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Stack
          sx={{ mb: 2 }}
          flexWrap={'wrap'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={2}
        >
          <Typography variant="h3" component="h1">
            Cliente
          </Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Button variant="contained" onClick={() => handleViewClick(hash)}>Ver Pedidos</Button>
            {/* 
            <Button variant="contained" color="error">
              Block Account
            </Button> */}
          </Stack>
        </Stack>

        <CustomerInfoCard customer={customer}  />

        <Divider sx={{ my: 3 }} />

        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: 3 }}>
          <Typography variant="h3" component="h1">
            Pedidos
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card sx={{ mb: 3 }}>
              <Stack sx={{ px: 4, py: 2 }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Stack height={'100%'} direction={'row'}>
                    <Stack flex={1} spacing={2}>
                      <Stack>
                        <Typography variant="subtitle1">Pagos</Typography>
                        <Typography variant="h3" component="h1">
                          {totalPaid}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ mb: 3 }}>
              <Stack sx={{ px: 4, py: 2 }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Stack height={'100%'} direction={'row'}>
                    <Stack flex={1} spacing={2}>
                      <Stack>
                        <Typography variant="subtitle1">Pendentes</Typography>
                        <Typography variant="h3" component="h1">
                          {totalPending}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Stack>
            </Card>
          </Grid>          
          <Grid item xs={12} md={3}>
            <Card sx={{ mb: 3 }}>
              <Stack sx={{ px: 4, py: 2 }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Stack height={'100%'} direction={'row'}>
                    <Stack flex={1} spacing={2}>
                      <Stack>
                        <Typography variant="subtitle1">Cancelados</Typography>
                        <Typography variant="h3" component="h1">
                          {totalCanceled}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Stack>
            </Card>
          </Grid>       
          <Grid item xs={12} md={3}>
            <Card sx={{ mb: 3 }}>
              <Stack sx={{ px: 4, py: 2 }} spacing={4}>
                <Grid item xs={12} md={4}>
                  <Stack height={'100%'} direction={'row'}>
                    <Stack flex={1} spacing={2}>
                      <Stack>
                        <Typography variant="subtitle1">Rejeitados</Typography>
                        <Typography variant="h3" component="h1">
                          {totalRejected}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}


