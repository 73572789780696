import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Dialog,
  Button,
  Select, 
  MenuItem,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import useAuth from 'hooks/useAuth';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';

type AffiliateFormDialogProps = {
  open: boolean;
  onClose: () => void;
};

function AffiliateFormDialog({ open, onClose }: AffiliateFormDialogProps) {
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useAuth();

  const CreateInvoiceSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório!'),
  });
  const UpdateInvoiceSchema = Yup.object().shape({
    name: Yup.string().required().required('Nome é obrigatório!'),
  });

  const defaultValues = {
    id: '',
    id_cliente: '',
    customer_id: '',
    document: '',
    data: '',
    created_at: '',
    updated_at: '',
    status: '',
    hash: '',
    views: '',
    price: '',
    name: '',
    description: '',
  };

  const methods = useForm({
    resolver: yupResolver(CreateInvoiceSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const inputMaskRef = useRef(null);

  useEffect(() => {
    reset(defaultValues);
    if (inputMaskRef.current) {
      // eslint-disable-next-line no-new
      new InputMask({ mask: '(99) 99999-9999' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const compartilharWhatsApp = async () => {
    try {
      window.open(`https://api.whatsapp.com/send?text=Chega+de+ter+dor+de+cabe%C3%A7a+para+cobrar+os+seus+clientes%21%0A%0AFa%C3%A7a+como+eu%2C+venha+para+a+PagPro%21%0A%0ACadastre-se+atrav%C3%A9s+do+link+e+venha+fazer+parte+desta+fam%C3%ADlia%3A+%0Ahttps%3A%2F%2Fpagpro.com.br%2F${user?.id}`);  
    } catch (error) {
      console.error(error);
    }
  };

  const compartilharFacebook = async () => {
    try {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=https://pagpro.com.br/${user?.id}`);  
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values) => {
    try {        
      window.open(`https://api.whatsapp.com/send?text=Chega+de+ter+dor+de+cabe%C3%A7a+para+cobrar+os+seus+clientes%21%0A%0AFa%C3%A7a+como+eu%2C+venha+para+a+PagPro%21%0A%0ACadastre-se+atrav%C3%A9s+do+link+e+venha+fazer+parte+desta+fam%C3%ADlia%3A+%0Ahttps%3A%2F%2Fpagpro.com.br%2F${user?.id}`);  
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{'Link de Indicacao'}</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            {(
              <Stack direction={'row'} spacing={2}>
                <RHFTextField name="link" label="Copie o Link abaixo:" value={`https://pagpro.com.br/${user?.id}`} />
              </Stack>
            )}
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <LoadingButton variant="contained" onClick={compartilharWhatsApp}>
            Compartilhar no WhatsApp
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            Fechar
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default AffiliateFormDialog;
