import { useState } from 'react';
import { Box, Button, Container, FormControlLabel, IconButton, MenuItem, Stack, Switch, Table, TableBody, TableContainer, TablePagination, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { useAffiliateList, IAffiliate } from './affiliates.hooks';
import { useNavigate } from 'react-router';
import { TableEmptyRows, TableHeadCustom, TableSearchNotFound, TableSelectedActions } from 'components/table';
import AffiliatesTableRow from '../../../components/affiliates/AffiliatesTableRow';
import { PATH_DASHBOARD } from 'routes/paths';
import Iconify from 'components/Iconify';
import useTable, { emptyRows, getComparator } from 'hooks/useTable';
import { fDateBusca, fPaymentStatusOther } from '../../../utils/formatTime';
import Scrollbar from 'components/Scrollbar';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { CsvBuilder } from 'filefy';
import AffiliateFormDialog from './AffiliateFormDialog';

export default function AffiliateListPage() {
  const { themeStretch } = useSettings();
  const { data = [], isLoading } = useAffiliateList();
  const [pageSize, setPageSize] = useState(25);
  const navigate = useNavigate();

  const handleViewClick = (row) => {
    // window.open(`https://pgto.pro/${row.hash}`, '_blank');
  };

  const [filterName, setFilterName] = useState('');

  const [filterStatus, setfilterStatus] = useState('Todos');

  const [filterOrderDateStart, setfilterOrderDateStart] = useState(null);
  const [filterOrderDateEnd, setfilterOrderDateEnd] = useState(null);

  const handleViewRow = (id) => {
    // navigate(PATH_DASHBOARD.general.ordersById.replace(':id', id));
    // Selecione todas as linhas
  };

  
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'orderDate' });

  const dataFiltered = applySortFilter({
    data,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
    filterOrderDateStart,
    filterOrderDateEnd,
  });
  
  const exportAllSelectedRows = () => {
    const selectedRows = dataFiltered.filter((data, index) => data.id === selected[index])
    const csvBuilder = new CsvBuilder("relatorio_de_indicados.csv")
      .setColumns(TABLE_HEAD.map(col => col.label))
      .addRows(selectedRows.map(rowData => TABLE_HEAD.map(col => rowData[col.id])))
      .exportFile();
  }

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const onCloseForm = () => {
    setIsFormModalOpen(false);
  };
  const handleLinkClick = () => {
    setIsFormModalOpen(true);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ID', flex: 1 },
    { id: 'nome', label: 'Nome', flex: 1 },
    { id: 'email', label: 'E-mail', flex: 1 },
    { id: 'celular', label: 'Telefone', flex: 1 },
    { id: 'status', label: 'Status', flex: 1 },
  ];

  return (
    <Page title="Indicações">
      <Container
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        maxWidth={themeStretch ? false : 'xl'}
      >
          <Typography variant="h3" component="h1" paragraph>
            Indicações
          </Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <HeaderBreadcrumbs
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.root },
                { name: 'Indicações' },
              ]}
              action={''}
              heading={undefined} sx={undefined} />
              <Button variant="outlined" onClick={handleLinkClick}>
                Link de Indicação
              </Button>
            </Stack>
        <Box sx={{ flex: 1 }}>
          
            <Scrollbar sx={{ minWidth: 800, position: 'relative' }}>
              <TableContainer>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={data.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        data.map((row) => row.id)
                      )
                    }
                    actions={
                      <Stack spacing={1} direction="row">
                        <Tooltip title="Download PDF">
                          <IconButton color="primary" onClick={() => exportAllSelectedRows()}>
                            <Iconify icon={'eva:download-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                  />
                )}

              <Table size={'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        data.map((row) => row.id)
                      )
                    }
                  />
                  <TableBody>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <AffiliatesTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                      />
                    ))}

                    <TableEmptyRows height={dense ? 56 : 76} emptyRows={emptyRows(page, rowsPerPage, data.length)} />
                  </TableBody>

                  {/* {isNotFound && <TableSearchNotFound />} */}
                </Table>
              </TableContainer>
            </Scrollbar>
            
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={dataFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />

              <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label="Dense"
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
              />
            </Box>
         </Box>
        <AffiliateFormDialog open={isFormModalOpen} onClose={onCloseForm} />
      </Container>
    </Page>
  );
}

function applySortFilter({
  data,
  comparator,
  filterName,
  filterStatus,
  filterOrderDateStart,
  filterOrderDateEnd,
  // filterExpiration,
}) {
  const stabilizedThis = data.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  data = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    data = data.filter(
      (item) =>
        item.id === filterName
        ||
        (item.customer.name).toLowerCase().indexOf((filterName).toLowerCase()) !== -1
        ||
        (item.customer.email).toLowerCase().indexOf((filterName).toLowerCase()) !== -1
        ||
        (item.items[0].description).toLowerCase().indexOf((filterName).toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'Todos') {
    data = data.filter((item) => item.status === fPaymentStatusOther(filterStatus));
  }

  if (filterOrderDateStart && filterOrderDateEnd) {
    data = data.filter(
      (item) =>
      fDateBusca(item.created_at) >= fDateBusca(filterOrderDateStart) &&
      fDateBusca(item.created_at) <= fDateBusca(filterOrderDateEnd)
    );
  }

  return data;
}