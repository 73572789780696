import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Dialog,
  Button,
  Select, 
  MenuItem,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Input,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { IPlan, useCreatePlan, useUpdatePlan } from './plans.hooks';
import Iconify from 'components/Iconify';
import _ from 'lodash';
import useTable from 'hooks/useTable';
import InputMoney from 'components/inputMoney/InputMoney';

type PlanFormDialogProps = {
  open: boolean;
  onClose: () => void;
  editItem?: IPlan;
  props?: any;
};

function PlanFormDialog({ open, onClose, editItem, props }: PlanFormDialogProps) {
  const { mutateAsync: createItemAsync } = useCreatePlan();
  const { mutateAsync: updateItemAsync } = useUpdatePlan();
  const isEdit = !!editItem;

  const defaultValues = {
    id: '',
    id_cliente: '',
    customer_id: '',
    document: '',
    data: '',
    created_at: '',
    updated_at: '',
    status: '',
    hash: '',
    views: '',
    price: '',
    name: '',
    description: '',
    redirect: '',
    redirect_cancelled: '',
    redirect_pending: '',
    sku: '',
    stock: '',
    stockstatus: '',
    type: '',
    interval: '',
    period: '',
    length: '',
    signup_fee: '',
    trial_length: '',
    trial_period: '',
    inventory: '',
    recovery_failed_freq: '',
    c_payment_methods: '',
    payment_method_sel: '',
    tag: '',
    active_redirect: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(editItem || defaultValues);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, editItem]);

  const onSubmit = async (values) => {
    try {
      if (isEdit) {
        await updateItemAsync(values);
      } else {
        await createItemAsync(values);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const [redirect, setRedirect] = useState(props?.defaultDense || false);
  const onChangeRedirect = (event) => {
    setRedirect(event.target.checked);
  };

  const [recurrence, setRecurrence] = useState(props?.defaultDense || false);
  const onChangeRecurrence = (event) => {
    setRecurrence(event.target.checked);
  };

  const [inventory, setInventory] = useState(props?.defaultDense || false);
  const onChangeInventory = (event) => {
    setInventory(event.target.checked);
  };

  const formatCurrency = (value) => {
    const formattedValue = parseFloat(value.replace(/[^\d]/g, '')) / 100;
    return formattedValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const setFieldValue = (field, value) => {
    methods.setValue(field, value, { shouldValidate: true });
  };
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    const formattedValue = value;
    setFieldValue(name, formattedValue);
  };

  const valuePrice = editItem?.price;

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? 'Editar plano' : 'Adicionar novo plano'}</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            {isEdit && (
              <Stack spacing={2}>
                <RHFTextField name="name" label="Nome" style={{ width: '100%'}} /> 
                <RHFTextField name="description" label="Descrição" style={{ width: '100%'}} multiline rows={4} />
                <InputMoney name="price" label="Valor R$" placeholder="0.00" onChange={onChange} value={valuePrice} {...props} />
              <Stack spacing={2}>                  
                <FormControlLabel
                  control={<Switch checked={inventory} onChange={onChangeInventory} name='active_interval' />}
                  label="Inventário"             
                />
                  <Stack spacing={2} style={{ display: (inventory) ? 'block' : 'none' }} >
                    <RHFTextField name="sku" label="ID/SKU" placeholder="ID/SKU" />
                    <RHFTextField name="stock" label="Estoque" placeholder="0" />
                    <Stack spacing={2}>
                      Status do estoque
                    <Controller
                      name="stockstatus"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} variant="outlined" label="Status do estoque" name="stockstatus" fullWidth>
                          <MenuItem value="Disponivel">Disponivel</MenuItem>
                          <MenuItem value="Pausado">Pausado</MenuItem>
                        </Select>
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={2}>                  
                <FormControlLabel
                  control={<Switch checked={recurrence} onChange={onChangeRecurrence} name='active_interval' />}
                  label="Recorrência"             
                />
                  <Stack spacing={2} style={{ display: (recurrence) ? 'block' : 'none' }} >
                    <strong>Frequência</strong>
                    <Controller
                      name="interval"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} variant="outlined" label="Intervalo" name="interval" fullWidth>
                          <MenuItem value="1">A cada 1</MenuItem>
                          <MenuItem value="2">A cada 2</MenuItem>
                          <MenuItem value="3">A cada 3</MenuItem>
                          <MenuItem value="4">A cada 4</MenuItem>
                          <MenuItem value="5">A cada 5</MenuItem>
                          <MenuItem value="6">A cada 6</MenuItem>
                        </Select>
                      )}
                    />
                    <Controller
                      name="period"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} variant="outlined" label="Período" name="period" fullWidth>
                          <MenuItem value="day">Dia</MenuItem>
                          <MenuItem value="week">Semana</MenuItem>
                          <MenuItem value="month">Mês</MenuItem>
                          <MenuItem value="year">Ano</MenuItem>
                        </Select>
                      )}
                    />
                </Stack>
              </Stack>
              <Stack spacing={2}>                  
                <FormControlLabel
                  control={<Switch checked={redirect} onChange={onChangeRedirect} name='active_redirect' />}
                  label="Redirecionamentos personalizados"             
                />
                <Stack spacing={2} style={{ display: (redirect) ? 'block' : 'none' }} >
                  <small>
                    Redirecione seus clientes após concluir o pagamento deste plano.
                    <br/>
                    Insira o URL para redirecionar seus clientes com base no status do pagamento.
                    <br/>
                  </small>
                  <RHFTextField name="redirect" label="Pagamento aprovado" placeholder="https://www.pagpro.com.br" />
                  <RHFTextField name="redirect_pending" label="Pagamento pendente" placeholder="https://www.pagpro.com.br" />
                  <RHFTextField name="redirect_cancelled" label="Pagamento cancelado" placeholder="https://www.pagpro.com.br" />
                </Stack>
              </Stack>
            </Stack>
            )}
            {!isEdit && (
              <Stack spacing={2}>
                <RHFTextField name="name" label="Nome" style={{ width: '100%'}} /> 
                <RHFTextField name="description" label="Descrição" style={{ width: '100%'}} multiline rows={4} />
                <InputMoney name="price" label="Valor R$" placeholder="0.00" onChange={onChange} value={valuePrice} {...props} />
              <Stack spacing={2}>                  
                <FormControlLabel
                  control={<Switch checked={inventory} onChange={onChangeInventory} name='active_interval' />}
                  label="Inventário"             
                />
                  <Stack spacing={2} style={{ display: (inventory) ? 'block' : 'none' }} >
                    <RHFTextField name="sku" label="ID/SKU" placeholder="ID/SKU" />
                    <RHFTextField name="stock" label="Estoque" placeholder="0" />
                    <Stack spacing={2}>
                      Status do estoque
                    <Controller
                      name="stockstatus"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} variant="outlined" label="Status do estoque" name="stockstatus" fullWidth>
                          <MenuItem value="Disponivel">Disponivel</MenuItem>
                          <MenuItem value="Pausado">Pausado</MenuItem>
                        </Select>
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={2}>                  
                <FormControlLabel
                  control={<Switch checked={recurrence} onChange={onChangeRecurrence} name='active_interval' />}
                  label="Recorrência"             
                />
                  <Stack spacing={2} style={{ display: (recurrence) ? 'block' : 'none' }} >
                    <strong>Frequência</strong>
                    <Controller
                      name="interval"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} variant="outlined" label="Intervalo" name="interval" fullWidth>
                          <MenuItem value="1">A cada 1</MenuItem>
                          <MenuItem value="2">A cada 2</MenuItem>
                          <MenuItem value="3">A cada 3</MenuItem>
                          <MenuItem value="4">A cada 4</MenuItem>
                          <MenuItem value="5">A cada 5</MenuItem>
                          <MenuItem value="6">A cada 6</MenuItem>
                        </Select>
                      )}
                    />
                    <Controller
                      name="period"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} variant="outlined" label="Período" name="period" fullWidth>
                          <MenuItem value="day">Dia</MenuItem>
                          <MenuItem value="week">Semana</MenuItem>
                          <MenuItem value="month">Mês</MenuItem>
                          <MenuItem value="year">Ano</MenuItem>
                        </Select>
                      )}
                    />
                </Stack>
              </Stack>
              <Stack spacing={2}>                  
                <FormControlLabel
                  control={<Switch checked={redirect} onChange={onChangeRedirect} name='active_redirect' />}
                  label="Redirecionamentos personalizados"             
                />
                <Stack spacing={2} style={{ display: (redirect) ? 'block' : 'none' }} >
                  <small>
                    Redirecione seus clientes após concluir o pagamento deste plano.
                    <br/>
                    Insira o URL para redirecionar seus clientes com base no status do pagamento.
                    <br/>
                  </small>
                  <RHFTextField name="redirect" label="Pagamento aprovado" placeholder="https://www.pagpro.com.br" />
                  <RHFTextField name="redirect_pending" label="Pagamento pendente" placeholder="https://www.pagpro.com.br" />
                  <RHFTextField name="redirect_cancelled" label="Pagamento cancelado" placeholder="https://www.pagpro.com.br" />
                </Stack>
              </Stack>
            </Stack>
            )}
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Salvar
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default PlanFormDialog;
