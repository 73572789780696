import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Checkbox, TableRow, TableCell, Typography, Stack, Link } from '@mui/material';
// components
import Label from '../Label';
import { TableMoreMenu } from '../table';
import { fMobilePhone2 } from '../../utils/formatTime';

// ----------------------------------------------------------------------

AffiliatesTableRow.propTypes = {
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};

export default function AffiliatesTableRow({ row, selected, onSelectRow, onViewRow }) {
  const theme = useTheme();

  const { id, nome, email, celular, status } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="left">
        {id}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'uppercase' }}>
        {nome}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'lowercase' }}>
        {email}
      </TableCell>

      <TableCell align="left">
        {fMobilePhone2(celular)}
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (status === 1 && 'success') ||
            (status === null && 'success') ||
            (status === 0 && 'error') ||
            'default'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {
            (status === 1 && 'Ativo') ||
            (status === null && 'Ativo') ||
            (status === 0 && 'Bloqueado') ||
            status
          }
        </Label>
      </TableCell>

    </TableRow>
  );
}
