import { useState } from 'react';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import { useInvoiceList, IInvoice, useDeleteInvoice } from './invoices.hooks';
import InvoicesFormDialog from './InvoiceFormDialog';
import { useNavigate } from 'react-router';
import Link from 'theme/overrides/Link';
import { Label, Share } from '@mui/icons-material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';

export default function InvoiceListPage() {
  const { themeStretch } = useSettings();
  const { data = [], isLoading } = useInvoiceList();
  const [pageSize, setPageSize] = useState(25);
  const [editItem, setEditItem] = useState<IInvoice | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: deleteItemAsync } = useDeleteInvoice();
  const theme = useTheme();
  
  const onCloseForm = () => {
    setEditItem(null);
    setIsFormModalOpen(false);
  };
  const handleAddClick = () => {
    setEditItem(null);
    setIsFormModalOpen(true);
  };
  const handleEditClick = (row) => {
    setEditItem(row);
    setIsFormModalOpen(true);
  };

  const shareWhatsApp = (row) => {
    // Compartilhar no WhatsApp
    const text = `Olá, tudo bem? Aqui está o link para pagamento pelo ${row.name}: https://pgto.pro/${row.hash}`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
  };

  const shareFacebook = (row) => {
    // Compartilhar no Facebook
    const text = `Olá, tudo bem? Aqui está o link para pagamento pelo ${row.name}: https://pgto.pro/${row.hash}`;
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
  };

  const handleViewClick = (row) => {
    window.open(`https://pgto.pro/${row.hash}`, '_blank');
  };

  const handleDeleteClick = async (row) => {
    if (window.confirm('Tem certeza que deseja excluir este link?')) {
      await deleteItemAsync(row.id);
    }
  }


  const columns = [
    { field: 'hash', headerName: 'Link', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'subtitle2'} noWrap>
            <a href={`https://pgto.pro/${params.row.hash}`} target="_blank" rel="noopener noreferrer">
              {params.row.hash}
            </a>
          </Typography>
        </Stack>
      ),
    },
    { field: 'name', headerName: 'Nome', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'subtitle2'} noWrap sx={{ textTransform: 'captalize' }}>
            {params.row.name}
          </Typography>
        </Stack>
      ),
    },
    { field: 'price', headerName: 'Valor', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'subtitle2'} noWrap>
          {(params.row.price * 0.01).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          </Typography>
        </Stack>
      ),
    },
    { field: 'views', headerName: 'Acessos', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'subtitle2'} noWrap>
            {params.row.views}
          </Typography>
        </Stack>
      ),
    },
    { field: 'status', headerName: 'Status', flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'subtitle2'} noWrap>            
              {
                (params.row.status === '1' && 'Ativo') ||
                (params.row.status === '0' && 'Ativo') ||
                (params.row.status === null && 'Ativo') ||
                (params.row.status === '' && 'Ativo') ||
                params.row.status
              }
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          showInMenu
          key={`edit-${id}`}
          icon={<EditIcon />}
          label="Editar"
          className="textPrimary"
          onClick={() => handleEditClick(row)}
          color="inherit"
          placeholder={'Editar link'}
        />,
        <GridActionsCellItem
          showInMenu
          key={`view-${id}`}
          icon={<EyeIcon />}
          label="Ver"
          className="textPrimary"
          onClick={() => handleViewClick(row)}
          color="inherit"
          placeholder={'Ver link'}
        />,
        <GridActionsCellItem
          showInMenu
          key={`del-${id}`}
          icon={<DeleteIcon />}
          label="Excluir"
          className="textPrimary"
          onClick={() => handleDeleteClick(row)}
          color="inherit"
          placeholder={'Excluir link'}
        />,
        <GridActionsCellItem
          showInMenu
          key={`share-${id}`}
          icon={<WhatsAppIcon />}
          label="Compartilhar"
          className="textPrimary"
          onClick={() => shareWhatsApp(row)}
          color="inherit"
          placeholder={'Compartilhar no WhatsApp'}
        />,
        <GridActionsCellItem
          showInMenu
          key={`share-${id}`}
          icon={<FacebookIcon />}
          label="Compartilhar"
          className="textPrimary"
          onClick={() => shareFacebook(row)}
          color="inherit"
          placeholder={'Compartilhar no Facebook'}
        />,
      ],
    },
  ];

  return (
    <Page title="Produtos">
      <Container
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        maxWidth={themeStretch ? false : 'xl'}
      >
          <Typography variant="h3" component="h1" paragraph>
            Produtos
          </Typography>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <HeaderBreadcrumbs
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Produtos' },
          ]}
          action={''}
          heading={undefined} sx={undefined} />
          <Button variant="outlined" onClick={handleAddClick}>
            Adicionar produto
          </Button>
        </Stack>
        <Box sx={{ flex: 1 }}>
          <DataGrid
            getRowId={(row) => row.id}
            loading={isLoading}
            disableSelectionOnClick
            rows={data}
            editMode="row"
            columns={columns}
            density={'compact'}
            pagination
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Box>
        <InvoicesFormDialog open={isFormModalOpen} editItem={editItem} onClose={onCloseForm} />
      </Container>
    </Page>
  );
}
