// components
import TranslateIcon from '@mui/icons-material/Translate';
import PaymentIcon from '@mui/icons-material/Payment';
import SvgIconStyle from '../../../components/SvgIconStyle';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AssessmentIcon from '@mui/icons-material/Assessment';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  translate: <TranslateIcon />,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_invoice'),
  payment: <PaymentIcon />,
  groups: <GroupsIcon />
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Pagamentos', path: '/dashboard/orders', icon: ICONS.invoice },
      { title: 'Assinaturas', path: '/dashboard/subscriptions', icon: ICONS.invoice },
      { title: 'Clientes', path: '/dashboard/customers', icon: ICONS.groups },
      // { title: 'Two', path: '/dashboard/two', icon: ICONS.ecommerce },
      // { title: 'Three', path: '/dashboard/three', icon: ICONS.analytics },
    ],
  },

  // Mobile APP MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Catálogo',
    items: [
      {
        title: 'Planos',
        path: '/dashboard/plans',
        icon: ICONS.ecommerce,
      },
      {
        title: 'Produtos',
        path: '/dashboard/products',
        icon: ICONS.ecommerce,
      },
    ],
  },

  // SETTINGS
  // ----------------------------------------------------------------------
  {
    subheader: 'Configurações',
    items: [
      { title: 'Seu negócio', path: '/dashboard/seller', icon: ICONS.dashboard },
      { title: 'Para o cliente', path: '/dashboard/customer', icon: ICONS.analytics },
      // { title: 'Recuperação de renda', path: '/dashboard/config', icon: ICONS.user },
      { title: 'API e Webhooks', path: '/dashboard/api', icon: ICONS.user },
      { title: 'Políticas', path: '/dashboard/politics', icon: ICONS.user },
    ],
  },
];

export default sidebarConfig;
