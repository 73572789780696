 import axios from "axios";
import endpoints from "endpoints";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export interface IProduct {
  id: string;
  id_cliente: string;
  customer_id: string;
  document: string;
  data: string;
  created_at: string;
  updated_at: string;
  status: string;
  hash: string;
  views: string;
  price: string;
  name: string;
  description: string;
  redirect: string;
  redirect_cancelled: string;
  redirect_pending: string;
  sku: string;
  stock: string;
  stockstatus: string;
  type: string;
  interval: string;
  period: string;
  length: string;
  signup_fee: string;
  trial_length: string;
  trial_period: string;
  inventory: string;
  recovery_failed_freq: string;
  c_payment_methods: string;
  payment_method_sel: string;
  tag: string;
}

type CreateProductParams = Pick<IProduct, "id" | "hash" | "price" | "name"> & { password: string};
type UpdateProductParams = Pick<IProduct, "id" | "hash" | "price" | "name">;

const listProduct = async () => axios.get(endpoints.listProduct);
const createProduct = async (values: CreateProductParams) => axios.post(endpoints.createProduct, values);
const updateProduct = async (values: UpdateProductParams) => axios.put(`${endpoints.updateProduct}/${values.id}`, values);
const deleteProduct = async (id: string) => axios.delete(`${endpoints.deleteProduct}/${id}`);

export const useProductList = () => useQuery(endpoints.listProduct, async () => {
  const data = await listProduct() 
  return data as unknown as IProduct[];
});

export const useCreateProduct = () =>
  useMutation({
    mutationFn: createProduct,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listProduct, (oldData: IProduct[] = []) => [newItem, ...oldData]);
      window.location.reload();
      toast.success(`Produto crado com sucesso!`);
    },
  });

export const useUpdateProduct = () =>
  useMutation({
    mutationFn: updateProduct,
    onSuccess: ({ data: newItem }) => {
      // queryClient.setQueryData(endpoints.listProduct, (oldData: IProduct[] = []) =>
      //   oldData.map((item) => (item.id === newItem.id ? newItem : item))
      // );
      window.location.reload();
      toast.success(`Produto atualizado com sucesso!`);
    },
  });

  
export const useDeleteProduct = () =>
  useMutation({
    mutationFn: deleteProduct,
    onSuccess: ({ data: newItem }) => {
      window.location.reload();
      toast.success(`Produto excluído com sucesso!`);
    },
  });


